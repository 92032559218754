<template>
  <div class="app-container">

    <a-card class="box-card">
      <a-form ref="config" :form="form" :layout="formLayout">
        <a-form-item
          label="企业"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-select
            option-label-prop="tt"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            show-search
            allow-clear
            v-decorator="[
              'supplier_id',
              {
                rules: [
                  { required: true, message: '企业名称' },
                ],
              },
            ]"
            placeholder="请输入公司名称"
            :default-active-first-option="false"
            @search="this.corporationSearch"
            @change="handlecorporationChange"
          >
            <a-select-option v-for="d in dataSource" :key="d.id" :tt="d.name">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="发送人"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-input
            v-decorator="['send_name', {
              rules: [
                { required: true, message: '必填项，请填写信息' },
              ],
            }]" />
        </a-form-item>
        <a-form-item
          label="电话"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-input
            v-decorator="['send_mobile', {
              rules: [
                { required: true, message: '必填项，请填写信息' },
              ],
            }]" />
        </a-form-item>
        <a-form-item
          label="拣货单状态回调地址"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-input
            v-decorator="['pick_list_callback_url', {
              rules: [
                { required: false, message: '必填项，请填写信息' },
              ],
            }]" />
        </a-form-item>
        <a-form-item
          label="入库商品状态回调地址"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-input
            v-decorator="['commodity_purchase_callback_url', {
              rules: [
                { required: false, message: '必填项，请填写信息' },
              ],
            }]" />
        </a-form-item>
      </a-form>
      <div class="op-container">
        <a-button type="primary" @click="handlePublish" style="margin-top: 20px">提交</a-button>
      </div>
    </a-card>
  </div>
</template>
<script>
import { supplier_list } from '@/api/supplier'
const pick = require('lodash.pick')
const { commonStoreConfig } = require('@/api/applet_shopmall_manage')
const debounce = require('lodash/debounce')
export default {
  name: 'ConfigCreate',
  data () {
    this.corporationSearch = debounce(this.handlecorporationSearch, 800)
    return {
      formLayout: 'horizontal',
      form: this.$form.createForm(this),
      dataSource: [],
      warehouseNormalList: [],
      config: {}
    }
  },
  computed: {
    formItemLayout () {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        } : {}
    }
  },
  created () {
  },
  methods: {
    handlecorporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handlecorporationSearch(value)
      }
    },
  handlecorporationSearch (value) {
    supplier_list({ name: value }).then((res) => {
      const result = res.data.entries || []
      // this.dataSource = !value ? [] : result
      this.dataSource = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
    })
  },
    // getAppletConfig () {
    //   getAppletConfig().then(res => {
    //     console.log('配置：', res)
    //     this.config = res.data
    //     this.setFormData()
    //   })
    // },
    handlePublish () {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          console.log(values)
          commonStoreConfig(values).then(res => {
            console.log(res)
          })
        } else {
          console.log(errors)
        }
      })
      console.log(this.config)
    },
    setFormData () {
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(this.config, ['e_warehouse_id', 'normal_warehouse_id', 'examine', 'oversold'])
        console.log('formData', formData)
        this.form.setFieldsValue(formData)
      })
    }
  }
}
</script>
<style>
@import "../../../assets/font/font.css";

.a-card {
  margin-bottom: 10px;
}

.a-tag + .a-tag {
  margin-left: 10px;
}

.input-new-keyword {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.avatar-uploader .a-upload {
  width: 145px;
  height: 145px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .a-upload:hover {
  border-color: #20a0ff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 145px;
  height: 145px;
  display: block;
}

.op-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/*谷歌*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/*火狐*/
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
